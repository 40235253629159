.container {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}

.content {
  margin-top: 140px;
}

.title {
  margin-top: 0;
  margin-bottom: 44px;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
}

.description {
  margin-bottom: 44px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}

.image {
  width: 60%;
  max-width: 660px;
  margin-left: auto;
}

@media (max-width: 1024px) {
  .content {
    margin-top: 120px;
  }

  .title {
    font-size: 36px;
    line-height: 1.2;
  }

  .description {
    font-size: 16px;
  }

  .image {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .content {
    margin-top: 40px;
    margin-bottom: 60px;
    text-align: center;
  }

  .buttons > * {
    width: 100%;
    padding: 13px;
    font-size: 14px;
  }

  .image {
    display: none;
  }
}

@media (max-width: 575px) {
  .content {
    margin-top: 20px;
  }
}
